<template>
  <div class="popRealisation">
    <div class="int">
      <img :src="data.img" :alt="data.title" />
      <div class="textes">
        <h2 class="title">{{ data.title }}</h2>
        <div class="infos">
          <div class="description">{{ data.descriptionPlus }}</div>
          <div class="techno" v-if="data.techno">
            Technologies : {{ data.techno }}
          </div>
        </div>
      </div>
      <svg
        class="close"
        @click="$emit('close')"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
        shape-rendering="geometricPrecision"
      >
        <path data-v-5bf9ea6d="" d="M18 6L6 18"></path>
        <path data-v-5bf9ea6d="" d="M6 6l12 12"></path>
      </svg>
    </div>
    <div class="bg" @click="$emit('close')"></div>
  </div>
</template>

<script>
export default {
  name: "popRealisation",
  props: ["data"]
};
</script>

<style scoped>
.popRealisation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popRealisation .bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.15s ease;
}
.popRealisation .infos {
  width: 90%;
  line-height: 20px;
}
.popRealisation .int {
  background: var(--background-primary);
  color: var(--text-primary);
  position: relative;
  z-index: 1;
  padding: 1.5rem;
  display: flex;
  width: 60%;
  animation: slideUpIn 0.15s ease;
}
.popRealisation .int img {
  margin-right: 1.5rem;
  flex: 0 0 auto;
}
.popRealisation .int .description {
  overflow-wrap: break-word;
  white-space: pre-line;
  padding: 1rem 0;
}
.popRealisation .int .techno {
  color: var(--text-accent);
}
.popRealisation .int .close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideUpIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 1400px) {
  .popRealisation .int {
    width: 80%;
  }
}
@media only screen and (max-width: 1000px) {
  .popRealisation {
    align-items: flex-start;
  }
  .popRealisation .int {
    width: 100%;
    flex-direction: column;
    height: 100%;
    padding-top: 4.5rem;
  }
  .popRealisation .int img {
    margin-right: 0;
    max-width: 400px;
    margin: 0 auto;
  }
  .popRealisation .int .textes {
    margin-top: 1.5rem;
    width: inherit;
    max-width: 400px;
    margin: 1.5rem auto;
  }
}
@media only screen and (max-width: 450px) {
  .popRealisation .int img {
    width: 100%;
  }
}
</style>
