<template>
  <div class="Portfolio">
    <header-nav></header-nav>
    <div class="titlePage">
      <h1 class="upper title">Réalisations</h1>
    </div>
    <main id="container">
      <section class="projects">
        <article
          class="project"
          v-for="(n, k) in realisations"
          :key="k"
          @click="
            popRealisationKey = k;
            popRealisationEtat = true;
          "
        >
          <div class="image" style="background: #bfbfbf">
            <img :src="n.img" :alt="n.title" />
          </div>
          <div class="text">
            <h2>{{ n.title }}</h2>
            <h3>{{ n.description }}</h3>
          </div>
        </article>
      </section>
    </main>
    <popRealisation
      v-if="popRealisationEtat"
      :data="realisations[popRealisationKey]"
      @close="popRealisationEtat = false"
    ></popRealisation>
  </div>
</template>

<script>
// import topArrow from "@/components/TopArrow";
import HeaderNav from "../components/HeaderNav";
import popRealisation from "../components/popRealisation";

export default {
  name: "Portfolio",
  data() {
    return {
      popRealisationEtat: false,
      popRealisationKey: null,
      realisations: [
        {
          img: "/img/portfolio/sira.jpg",
          title: "Sira",
          description: "Suivi radiologique des Armées",
          descriptionPlus:
            "Le Système d'Information de Radioprotection des Armées (SIRA) est développé pour le Service de santé des armées. \n " +
            "Développée sous forme de SPA (Single Page Application), cette application permet de répondre au besoin du suivi des expositions radiologiques en sein des armées.",
          techno:
            "Nodejs, Angular, PostgreSQL, API RESTful, versioning, Docker. Méthode DevOps, agilité, intégration continue."
        },
        {
          img: "/img/portfolio/myadju.jpg",
          title: "MyAdju",
          description: "Automatisation du service courant",
          descriptionPlus:
            "L’application MyAdju a été créée afin de simplifier le travail des adjudants de compagnie et d'automatiser le service courant. \n" +
            "Les fiches de décompte de permissions sont générées automatiquement, les circuits de visas dématérialisés, " +
            "et le cahier de mouvement des rationnaires est édité automatiquement...",
          techno:
            "Symfony, PostgreSQL, Versioning, Docker. Méthode DevOps, agilité, intégration continue."
        },
        {
          img: "/img/portfolio/default.jpg",
          title: "Comete",
          description: "Gestion des compétences militaires",
          descriptionPlus:
            "L’application COMETE permet le suivi des compétences et des formations de chaque marin. \n" +
            " L’application étant embarquée sur les navires, elle offre la possibilité de suivre les " +
            "formations des militaires pour le poste tout au long d’une mission.",
          techno:
            "Symfony, SQL Server, Versioning, Docker. Méthode DevOps, agilité, intégration continue."
        },
        {
          img: "/img/portfolio/portfolio.jpg",
          title: "Portfolio",
          description: "Présentation des compétences et projets ",
          descriptionPlus:
            "Le portfolio est mon CV en ligne, il me permet de montrer mes compétences et présenter certains de mes projets. \n" +
            "Celui-ci a été réalisé de A à Z à l’aide de technologies JS.",
          techno:
            "Vue.js, Javascript, Webpack, html/css, versioning, déploiement continu."
        },
        {
          img: "/img/portfolio/alternance.jpg",
          title: "Alternance",
          description: "Journal d'alternance dématérialisé",
          descriptionPlus:
            "Le journal dématérialisé m'a permis de mettre en pratique de nombreuses technologies et pratiques vues lors de mes veilles que je voulais utiliser dans un cas concret.",
          techno:
            "Vue.js, Node.JS, Webpack, Axios, MongoDB, JWT, Nginx, versioning"
        },
        {
          img: "/img/portfolio/ffmc.jpg",
          title: "Points noirs",
          description: "Signalement des dangers routiers",
          descriptionPlus:
            "LDans le cadre d’une association à but non lucratif, j’ai effectué une Progressive Web Application (PWA). \n " +
            "Cette application communautaire est destinée à recenser les dangers routiers et à prévenir les autorités compétentes afin de corriger le problème. \n",
          techno:
            "Vue.js, Node.JS, Webpack, Axios, MongoDB, JWT, Nginx, versioning"
        },
        {
          img: "/img/portfolio/mdl.jpg",
          title: "Maison des Ligues",
          description: "Gestion d’un congrès sportif",
          descriptionPlus:
            "La maison des ligues Lorraine est une structure financée par le Conseil Régional du Grand Est. \n " +
            "Elle a pour mission de fournir des espaces et des services aux différentes ligues sportives régionales et à d’autres structures hébergées.",
          techno: "Symfony, MySQL, TWIG, html/css, Javascript, versioning"
        },
        {
          img: "/img/portfolio/gsb.jpg",
          title: "GSB",
          description: "Gestion de frais des visiteurs médicaux",
          descriptionPlus:
            "GSB est donc une application Web en PHP gérant le suivi de remboursement de fiches de frais de visiteurs médicaux.",
          techno: "PHP, MySQL, html/css, Javascript, versioning."
        },
        {
          img: "/img/portfolio/nolark.jpg",
          title: "Nolark",
          description: "Site vitrine de vente de casques de moto",
          descriptionPlus:
            "Site vitrine pour la société fictive de vente de casques de moto Nolark.",
          techno: "PHP, MySQL, Javascript, html/css, versioning."
        }
      ]
    };
  },
  metaInfo: {
    title: "Réalisations"
  },
  components: {
    HeaderNav,
    popRealisation
  }
};
</script>

<style scoped>
.titlePage {
  display: flex;
  justify-content: center;
  width: 100%;
}

h2 {
  text-transform: none;
  width: 100%;
}

section.projects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 100px;
}

article.project {
  flex-direction: column;
  width: 400px;
  margin: 25px;
  display: flex;
  cursor: pointer;
}

article.project .image {
  background: #f4b942;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 270px;
  transition: 0.2s ease-in-out;
}
article.project img {
  transform: scale(0.83);
  box-shadow: 1px 1px 3px rgba(150, 150, 150, 0.7);
}

article.project .text {
  flex-wrap: wrap;
  display: flex;
  justify-content: start;
  margin-top: 10px;
}

article.project h2 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
}

article.project h3 {
  font-weight: 400;
  font-size: 18px;
}

article.project:hover .image {
  margin-top: -10px;
}

@media only screen and (max-width: 750px) {
  section.projects {
    padding: 0;
  }
  article.project .image {
    width: 100%;
    height: 270px;
  }
  article.project img {
    width: 100%;
  }
}
</style>
